export const userRoles = {
	1: { name: 'Seller', label: 'Seller', text: 'seller' },
	2: { name: 'CO_SELLER', label: 'Co-Seller', text: 'coSeller' },
	3: { name: 'BUYER_AGENT', label: "Buyer's Agent", text: 'buyerAgent' },
	4: {
		name: 'CO_BUYER_AGENT',
		label: 'Co-Buyer’s Agent',
		text: 'coBuyerAgent'
	},
	5: { name: 'TITLE_AGENT', label: 'Title Agent', text: 'titleAgent' },
	6: { name: 'ESCROW_AGENT', label: 'Escrow Agent', text: 'escrowAgent' },
	7: { name: 'SELLER_AGENT', label: 'Seller Agent', text: 'sellerAgent' },
	8: { name: 'ASSISTANT', label: 'Assistant', text: 'assistant' },
	9: { name: 'BUYER', label: 'Buyer', text: 'buyer' },
	10: {
		name: 'CO_SELLER_AGENT',
		label: "Co-Seller's Agent",
		text: 'coSellerAgent'
	},
	11: { name: 'LOAN_OFFICER', label: 'Loan Officer', text: 'loanOfficer' },
	12: { name: 'CO_BUYER', label: 'Co-Buyer', text: 'coBuyer' }
};

export const txStatus = {
	0: 'DELETED',
	1: 'ONGOING',
	2: 'DRAFT',
	3: 'COMPLETED',
	4: 'CANCELLED',
	DELETED: 0,
	ONGOING: 1,
	DRAFT: 2,
	COMPLETED: 3,
	CANCELLED: 4
};
