import { combineReducers } from '@reduxjs/toolkit';
import auth from 'app/auth/store';
import dashboard from 'app/main/dashboard/DashboardSlice';
import documents from 'app/main/documents/documentsSlice';
import earlySignup from 'app/main/EarlySignup/EarlySignupSlice';
import enquiryUser from 'app/main/Enquiry/EnquirySlice';
import registeredUser from 'app/main/RegisteredUsers/RegisteredUserSlice';
import transactions from 'app/main/transactions/TransactionsSlice';
import analytics from 'app/main/analytics/AnalyticsSlice';

import common from './commonSlice';
import fuse from './fuse';
import i18n from './i18nSlice';

const createReducer = asyncReducers =>
	combineReducers({
		auth,
		fuse,
		i18n,
		documents,
		dashboard,
		earlySignup,
		enquiryUser,
		registeredUser,
		common,
		transactions,
		analytics,
		...asyncReducers
	});

export default createReducer;
